const tco_navlinks = document.querySelectorAll("#site-offcanvas .has-dropdown > a");

for ( const link of tco_navlinks ) {
    link.addEventListener('click', function handleClick(event) {
        link.nextElementSibling.classList.toggle('open');

        // do things, and then
        event.preventDefault();
        return false;
    });
}